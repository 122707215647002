<template>
  <div class="header">
    <v-app-bar :color="whiteLabel.colors.primary" height="90" dark flat>
      <div class="text-center pl-7 pt-2">
        <v-img
          hidden
          :src="whiteLabel.images.logo"
          heigth="2"
          max-width="100"
          contain
        >
        </v-img>
        <div>{{ this.whiteLabel.labels.tagline }}</div>
      </div>

      <v-spacer></v-spacer>

      <div v-if="user.loggedIn && license" class="text-center">
        {{ license.buildingName }}<br />
        {{ license.buildingAddress }}
      </div>

      <v-spacer></v-spacer>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="
              $router.push({
                name: 'userManual',
              })
            "
          >
            <v-icon v-bind="attrs" v-on="on" dense>
              mdi-file-document
            </v-icon></v-btn
          >
        </template>
        <span>Manual de Uso</span>
      </v-tooltip>

      <v-tooltip top v-if="user.loggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="openSupport">
            <v-icon v-bind="attrs" v-on="on" dense> mdi-forum </v-icon></v-btn
          >
        </template>
        <span>Ayuda | Soporte Técnico</span>
      </v-tooltip>

      <v-tooltip top v-if="user.loggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click.prevent="signOut">
            <v-icon v-bind="attrs" v-on="on" dense> fa-sign-out </v-icon></v-btn
          >
        </template>
        <span>Cerrar sesión</span>
      </v-tooltip>

      <v-tooltip top v-if="!user.loggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click.prevent="signIn">
            <v-icon v-bind="attrs" v-on="on" dense> fa-sign-in </v-icon></v-btn
          >
        </template>
        <span>Iniciar sesión</span>
      </v-tooltip>
    </v-app-bar>
  </div>
</template>

<script>
import { auth } from "@/fb";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dialog: false,
  }),

  computed: {
    ...mapGetters({
      user: "user",
      license: "license",
      whiteLabel: "whiteLabel",
    }),
  },

  methods: {
    signOut() {
      auth.signOut().then(() => {
        this.$router.push("login");
      });
    },
    signIn() {
      this.$router.push("login");
    },
    openSupport() {
      window.open(
        "https://api.whatsapp.com/send/?phone=" + this.whiteLabel.support.whatsApp + "&type=phone_number&app_absent=0&text=Solicito ayuda / asistencia técnica. Mi código de licencia es " +
          this.user.data.building,
        "_blank"
      );
    },
  },
};
</script>
