<template>
  <div>
    <v-dialog v-if="!signinin" v-model="dialog" max-width="370" persistent>
      <v-card>
        <v-list-item>
          <v-list-item-icon>
            <v-img src="@/assets/logo-tappbell.png" width="65"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="headline">
              Panel de Control
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-content>
            <v-form ref="form" @submit.prevent="resetpwd">
              <v-card-text>
                <v-text-field
                  v-model="newpassword"
                  label="Nueva clave"
                  type="password"
                  :rules="requiredRulePassword"
                  @input="inputChanged"
                  required
                >
                </v-text-field>
              </v-card-text>
              <v-card-text>
                <v-text-field
                  v-model="repeatpassword"
                  label="Repetición de clave"
                  type="password"
                  :rules="requiredRulePassword"
                  @input="inputChanged"
                  required
                  :error-messages="
                    repeatwrong
                      ? 'La nueva clave y su repetición deben ser iguales'
                      : ''
                  "
                >
                </v-text-field>
              </v-card-text>

              <v-card-actions class="justify-center">
                <v-btn
                  color="success"
                  type="submit"
                  :disabled="!isValid"
                  :loading="loading"
                >
                  <v-icon left>mdi-login</v-icon>
                  Cambiar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle class="text-center">
              <p>
                Sólo usuarios administradores de TappBell<br />
                pueden acceder a la administración remota.
              </p>
              <p>
                Para obtener su usuario y contraseña,<br />
                póngase en contacto con su administrador de<br />
                consorcio o con TappBell.
              </p>
              <p>
                ®TappBell es una marca registrada<br />
                <a target="_blank" href="https://tappbell.com"
                  >www.TappBell.com</a
                >
                -
                <a target="_blank" href="mailto:info@tappbell.com"
                  >info@tappbell.com</a
                >
              </p>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { auth, ff } from "@/fb";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      dialog: true,
      show: false,
      snackbar: {
        visible: false,
        timeout: 2000,
        text: "",
        color: "",
      },
      isValid: false,
      signinin: true,
      repeatwrong: false,
      newpassword: "",
      repeatpassword: "",
      requiredRulePassword: [
        (v) =>
          /.{6}/.test(v) || "La clave debe tener un mínimo de 6 caracteres",
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  mounted() {
    if (this.$router.currentRoute.query.link) {
      this.snackbar.text = "Ingresando...";
      this.snackbar.color = "success";
      this.snackbar.visible = true;
      auth.signInWithCustomToken(this.$router.currentRoute.query.link).then(
        (usercredential) => {
          this.snackbar.text = "Bienvenido " + usercredential.user.displayName;
          this.snackbar.color = "success";
          this.snackbar.visible = true;
          this.signinin = false;
        },
        (error) => {
          console.error(error);
          this.snackbar.text = error.message;
          this.snackbar.color = "error";
          this.snackbar.visible = true;
          this.$router.push("login");
        }
      );
    } else {
      this.signinin = false;
    }
  },
  methods: {
    inputChanged() {
      this.repeatwrong = false;
      if (this.$refs.form.validate()) {
        if (this.newpassword != this.repeatpassword) {
          this.isValid = false;
          this.repeatwrong = true;
        } else {
          this.isValid = true;
        }
      } else {
        this.isValid = false;
      }
    },
    async resetpwd() {
      const data = {
        uid: this.user.data.id,
        password: this.newpassword,
      };
      this.loading = true;
      ff.httpsCallable("resetPassword")(data)
        .then((result) => {
          this.loading = false;
          if (!result.data.error) {
            this.snackbar.visible = true;
            this.snackbar.text = "Clave cambiada...";
            this.snackbar.color = "success";

            this.$router.push("system");
          } else {
            this.snackbar.visible = true;
            this.snackbar.text =
              result.data.error.code + " - " + result.data.error.message;
            this.snackbar.color = "error";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
