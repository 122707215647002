var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('v-app-bar',{attrs:{"color":_vm.whiteLabel.colors.primary,"height":"90","dark":"","flat":""}},[_c('div',{staticClass:"text-center pl-7 pt-2"},[_c('v-img',{attrs:{"hidden":"","src":_vm.whiteLabel.images.logo,"heigth":"2","max-width":"100","contain":""}}),_c('div',[_vm._v(_vm._s(this.whiteLabel.labels.tagline))])],1),_c('v-spacer'),(_vm.user.loggedIn && _vm.license)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.license.buildingName)),_c('br'),_vm._v(" "+_vm._s(_vm.license.buildingAddress)+" ")]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
              name: 'userManual',
            })}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document ")])],1)]}}])},[_c('span',[_vm._v("Manual de Uso")])]),(_vm.user.loggedIn)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.openSupport}},[_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-forum ")])],1)]}}],null,false,432869694)},[_c('span',[_vm._v("Ayuda | Soporte Técnico")])]):_vm._e(),(_vm.user.loggedIn)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.signOut($event)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""}},'v-icon',attrs,false),on),[_vm._v(" fa-sign-out ")])],1)]}}],null,false,2965399835)},[_c('span',[_vm._v("Cerrar sesión")])]):_vm._e(),(!_vm.user.loggedIn)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.signIn($event)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""}},'v-icon',attrs,false),on),[_vm._v(" fa-sign-in ")])],1)]}}],null,false,208958651)},[_c('span',[_vm._v("Iniciar sesión")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }